.header-slider:not(.slick-slider)>div+div,
.header-slider--mobile:not(.slick-slider)>div+div{
    display: none;
}

.header-slider__item{
    position: relative;
    overflow: hidden;
}
.header-slider--mobile video,
.header-slider video{
    object-fit: cover;
}

.header-slider__body{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 30px;
}

.header-slider__desc{
    position: relative;
    z-index: 2;
}

.header-slider__hl{
    font-size: 40px;
    line-height: 1;
    text-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
    font-family: var(--font-pal-handel);
    text-transform: uppercase;
}

@media screen and (min-width: 768px){
    .header-slider__hl{
        font-size: 60px;
    }
}

@media screen and (min-width: 1200px){
    .header-slider__hl{
        font-size: 90px;
    }
}

.header-slider__body .btn-wrapper{
    margin-top: 30px;
}

.header-slider__body .btn-wrapper .btn{
    margin-right: 10px;
    margin-bottom: 15px;
}

.header-slider__body:after,
.header-image-mobile__img:after{
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background: linear-gradient(to bottom,  rgba(246,246,246,0) 0%,rgba(246,246,246,0.8) 73%,rgba(246,246,246,0.9) 84%,rgba(246,246,246,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@media screen and (max-width: 767px){
    .header-image-mobile {
        overflow: hidden;
    }
    .header-image-mobile__img{
        position: relative;
    }
    .header-image-mobile__img:after{
        height: 35%;
    }
    .header-slider__hl{
        margin-bottom: 25px;
    }

    .header-image-mobile__body{
        margin-top: -40px;
        z-index: 2;
        position: relative;
    }

    .header-image-mobile__body .btn{
        text-align: left;
        position: relative;
        margin-bottom: 10px;
    }
    .header-image-mobile__body .btn .icon{
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
    }
}