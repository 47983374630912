
.no-margin { margin: 0!important; }
/*margins*/
.mt5  {margin-top: 5px;}
.mt10 {margin-top: 10px;}
.mt15 {margin-top: 15px;}
.mt20 {margin-top: 20px;}
.mt30 {margin-top: 30px;}
.mt40 {margin-top: 40px;}

.mb0{margin-bottom: 0px; }
.mb5{margin-bottom: 5px; }
.mb10{margin-bottom: 10px; }
.mb15{margin-bottom: 15px; }
.mb20{margin-bottom: 20px; }
.mb30{margin-bottom: 30px; }
.mb40{margin-bottom: 40px; }
.mb50{margin-bottom: 50px; }
.mb100{margin-bottom: 100px; }

@media screen and (max-width: 767px){
    .mb15-xs{ margin-bottom: 15px; }
    .mb50{margin-bottom: 25px; }
    .text-center-xs {text-align: center;}
}

a.skiplink {
    position: fixed;
    left: -10000px;
    top: -10000px;
    z-index: 1000;
    font-size: 14px;
    padding: 3px;
}
a.skiplink:focus {
    left: 0;
    top: 0;
    outline: none;
    background-color: var(--color-primary);
    color: #fff;
}


.text-primary { color: var(--color-primary); }
.text-default { color: var(--color-default); }
.text-white { color: #fff; }
.text-grey { color: #828282; }

.bg-primary{ background: var(--color-primary); }
.bg-default{ background: var(--color-default); color: #fff; }
.bg-white{ background: #fff;}

.bg-gradient{
    position: relative;
    background: rgb(239,239,239);
    background: linear-gradient(to bottom,#efefef 0,#efefef 68%,#dadada 72%,#e8e8e8 84%,#e8e8e8 100%);
}

.isClickable, .isCursor, .is-video {cursor: pointer;}
.d-ib {display: inline-block;}
.d-b {display: block;}

.centered{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.skew{ transform: skew(-25deg); }
.skew-reverse{ transform: skew(25deg); }

.height-100{
    height: 100%;
}

.disp-flex{
    display: flex;
}

/*flex helpers*/
.flex {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
}
.flex__grow-1 {
    flex-grow: 1;
}
.flex__grow-0 {
    flex-grow: 0;
}

/* vertical dividing line*/
.divider-right:before {
    background: var(--color-grey-border);
    width: 1px;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
}

.divider-right--mb20:before {
    bottom: 20px;
}

.addthis-text{
    margin-right: 10px;
    position: relative;
    top: 5px;
}

@media screen and (min-width: 768px){
    .addthis-text-sm{
        margin-right: 10px;
        position: relative;
        top: 3px;
    }

    .addthis-text{
        top:3px;
    }

}


@media screen and (max-width: 767px){

    .d-ib {
        display: block;
        font-size: 14px;
        margin-bottom: 20px;
    }
}