.btn{
    font-family: var(--font-default);
}

.btn-unstyled{
    background: transparent;
    border:none;
    box-shadow: none;
}

.btn-primary{
    background: var(--color-primary);
    color: #fff;
    transition: all 150ms ease;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:active:focus{
    background: #bb0a13;
    color: #fff;
}

.btn-primary:active:focus {
    box-shadow: inset 0 5px 7px rgba(0,0,0,.125);
}

.btn-white{
    background: #fff;
    color: var(--color-primary);
}

.btn-black{
    background: var(--color-default);
    color: #fff;
}

.btn-black:hover,
.btn-black:focus,
.btn-black:active,
.btn-black:active:focus{
    background: #333;
    color: #fff;
}

.btn-grey{
    background: #d9d9d9;
    color: var(--color-default);
}

.btn-border{
    border: 2px solid var(--color-default);
    color: var(--color-default);
    background: transparent;
}
.wysiwyg a.btn-border {
    color: var(--color-default);
}
.wysiwyg a.btn-primary {
    color: #fff;
}

.btn-icon .icon{
    font-size: 25px;
    line-height: 0;
    vertical-align: .01em;
    vertical-align: -0.3em;
    margin-left: 0.2em;
}

.btn-icon.btn-icon--left .icon {
    margin-right: 0.2em;
    margin-left: 0;
}

.btn-switch-color{
    background: var(--color-primary);
    color: #fff;
    transition: all 150ms ease;
}

.btn-switch-color:hover{
    color: #fff;
    background: #bb0a13;
}

.btn-arrow{
    position: relative;
    overflow: hidden;
}

.btn-arrow:after {
    content: '';
    width: 50px;
    position: absolute;
    right: -7px;
    top: 0;
    bottom: 0;
    background: #fff;
    transform: skew(-20deg);
}

.btn-arrow.btn-icon .icon{
    margin-left: 0.7em;
    z-index: 1;
    position: relative;
    color: var(--color-primary);
}

.btn-arrow{
    padding-right: 12px;
}

.btn--360-icon .icon {
    font-size: 20px;
}

@media screen and (max-width: 767px){
    .btn.btn-lg{
        padding: 13px 20px;
    }
}
