/*row gutter*/
.row.row--gutter-width-0 {
    margin-left:0;
    margin-right:0;
}
.row.row--gutter-width-0>.col {
    padding-left:0;
    padding-right:0;
}

.row.row--gutter-width-3 {
    margin-left: -1.5px;
    margin-right: -1.5px;
}
.row.row--gutter-width-3>.col {
    padding-left: 1.5px;
    padding-right: 1.5px;
}

.row.row--gutter-width-22 {
    margin-left:-11px;
    margin-right:-11px;
}
.row.row--gutter-width-22>.col {
    padding-left:11px;
    padding-right:11px;
}

.row.row--gutter-width-80 {
    margin-left:-40px;
    margin-right:-40px;
}
.row.row--gutter-width-80>.col {
    padding-left:40px;
    padding-right:40px;
}


/*vertical gutter*/
.row--vertical-gutter-3 {
    margin-top: -3px;
    clear: both;
}
.row--vertical-gutter-3 > .col {
    margin-top: 3px;
}

.row--vertical-gutter-22 {
    margin-top: -22px;
    clear: both;
}
.row--vertical-gutter-22 > .col {
    margin-top: 22px;
}

.row--vertical-gutter-50 {
    margin-top: -50px;
    clear: both;
}
.row--vertical-gutter-50 > .col {
    margin-top: 50px;
}


/*Center cols*/
.row--inline-block {
    font-size: 0;
    text-align: center;
}
.row--inline-block>.col {
    display: inline-block;
    float: none;
    font-size: 16px;
    vertical-align: middle;
    text-align: left;
}
.row--inline-block>.col.text-center,
.row--inline-block.text-center>.col{
    text-align: center;
}

.row--inline-block>.col.text-right{
    text-align: right;
}

.row--inline-block--bottom>.col {
    vertical-align: bottom;
}

/* row--same-height */
.row--same-height__item {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
}
/*
 * the .row--same-height selector has to be after
 * .row--same-height__item to increase the specificity
 * in case of chaining the two selectors
 * (which is needed for nested rows)
 */
.row--same-height{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.row--same-height {
    flex-wrap: wrap;
}
.row--same-height.slick-slider {
    display: block;
}
.row--same-height:before,
.row--same-height:after {
    display: none;
}
.row--same-height > .col,
.row--same-height.slick-slider .col {
    display: flex;
    flex-direction: column;
    height: auto;
}

.row--same-height--center{
    justify-content: center;
}
@media screen and (min-width: 768px) {
    .row--same-height-sm .row--same-height__item {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
    }
    /*
     * the .row--same-height selector has to be after
     * .row--same-height__item to increase the specificity
     * in case of chaining the two selectors
     * (which is needed for nested rows)
     */
    .row--same-height-sm,
    .row--same-height-sm.slick-slider .slick-track {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .row--same-height-sm.slick-slider {
        display: block;
    }
    .row--same-height-sm:before,
    .row--same-height-sm:after {
        display: none;
    }
    .row--same-height-sm > .col,
    .row--same-height-sm.slick-slider .col {
        display: flex;
        flex-direction: column;
        height: auto;
    }

}
