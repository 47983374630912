.embed-responsive{
    width: 100%;
}

.embed-responsive-teaser-img {
    padding-top: 53.89%;
}

.embed-responsive-23by13 {
    padding-top: 56.36%;
}

.cover-4by3 {
    height: 133.33% !important;
    margin-top: -9.3% !important;
}

.embed-responsive-image-slide-bg {
    padding-top: 33.33%;
}

.embed-responsive-portal-heading-img{
    padding-top: 46.89%
}

.embed-responsive-2by1{
    padding-top: 50%
}

.embed-responsive-7by2{
    padding-top: 28.57%
}

.embed-responsive-7by6{
    padding-top: 85.78%
}

.embed-responsive-1by1{
    padding-top: 100%
}

.embed-responsive-16by5{
    padding-top: 31.25%
}

.embed-responsive-3by2{
    padding-top: 66.666%;
}


.embed-responsive-staff-img{
    padding-top: 92.857%;
}

.embed-responsive-serie-detail {
    padding-top: 105%;
}

.embed-responsive-serie-detail-small {
    padding-top: 80%;
}

.embed-responsive-epsolutions-timeline {
    padding-top: 37.33870%;
}

@media screen and (min-width: 768px){
    .embed-responsive-sm {
        position: relative;
        display: block;
        height: 0;
        padding: 0;
        overflow: hidden;
    }

    .embed-responsive-sm .embed-responsive-item-sm{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
    }

    .embed-responsive-1by1-sm{
        padding-top: 100%
    }

    .embed-responsive-news-slider-body-sm{
        padding-top: 112.4197%
    }
}

@media screen and (max-width: 767px){
    .embed-responsive-image-slide-bg {
        padding-top: 140%;
    }

    .embed-responsive-epsolutions-timeline {
        padding-top: 66.666666667%;
    }
}