body {
    font-size: 14px;
    color: var(--color-default);
    line-height: calc(20 / 14);
    font-family: var(--font-default);
    font-weight: 400;
    background: #fff;
}
@media screen and (min-width: 768px) {
    body {
        line-height: calc(24 / 16);
        font-size: 16px;
    }
}

.font-default {
    font-family:var(--font-default);
    font-weight: 400;
}

.font-trade-bold{
    font-family: var(--font-trade-gothic-bold);
}

.font-trade-regular{
    font-family: var(--font-trade-gothic-regular);
}
.font-pal-handel{
    font-family: var(--font-pal-handel);
}

.font-arial-bold, strong, b, .strong{
    font-family: Arial, sans-serif;
    font-weight: 700;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin: 0;
    /*font-family:var(--font-martel-family);*/
    font-weight: 400;
    color: inherit;
}
h1, .h1 {
    font-size: 30px;
    line-height: calc(35/30);
    font-family:var(--font-pal-handel);
}

h2, .h2 {
    font-size: 25px;
    line-height:calc(30/25);
}

h3, .h3 {
    font-size: 20px;
    line-height:calc(23/20);
    font-family: var(--font-trade-gothic-bold);
}

@media screen and (min-width: 992px){
    h1, .h1 {
        font-size: 60px;
        line-height: calc(65/60);
    }
    h2, .h2 {
        font-size: 35px;
        line-height:calc(40/35);
    }
}

@media screen and (min-width: 768px) {
    h1, .h1 {
        font-size: 45px;
        line-height: calc(50/45);
    }
    h2, .h2 {
        font-size: 30px;
        line-height:calc(35/30);
    }
}

