@font-face {
    font-family: "iconfont";
    src: url('/static/debug/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/static/debug/icons/font/iconfont.woff') format('woff'),
    url('/static/debug/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
                     font-size: 1.3333333333333333em;
                     line-height: 0.75em;
                     vertical-align: -15%;
                 }
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
                     width: 1.2857142857142858em;
                     text-align: center;
                 }

.icon-ul {
                     padding-left: 0;
                     margin-left: 2.14285714em;
                     list-style-type: none;
                 }
.icon-ul > li {
                     position: relative;
                 }
.icon-li {
                     position: absolute;
                     left: -2.14285714em;
                     width: 2.14285714em;
                     top: 0.14285714em;
                     text-align: center;
                 }
.icon-li.icon-lg {
                                         left: -1.85714286em;
                                     }

.icon-rotate-45 {
                     filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
                     -webkit-transform: rotate(45deg);
                     -ms-transform: rotate(45deg);
                     transform: rotate(45deg);
                 }

.icon-rotate-90 {
                     filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
                     -webkit-transform: rotate(90deg);
                     -ms-transform: rotate(90deg);
                     transform: rotate(90deg);
                 }
.icon-rotate-180 {
                     filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
                     -webkit-transform: rotate(180deg);
                     -ms-transform: rotate(180deg);
                     transform: rotate(180deg);
                 }
.icon-rotate-270 {
                     filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
                     -webkit-transform: rotate(270deg);
                     -ms-transform: rotate(270deg);
                     transform: rotate(270deg);
                 }
.icon-flip-horizontal {
                     filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
                     -webkit-transform: scale(-1, 1);
                     -ms-transform: scale(-1, 1);
                     transform: scale(-1, 1);
                 }
.icon-flip-vertical {
                     filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
                     -webkit-transform: scale(1, -1);
                     -ms-transform: scale(1, -1);
                     transform: scale(1, -1);
                 }
:root .icon-rotate-90,
                       :root .icon-rotate-180,
                                              :root .icon-rotate-270,
                                                                     :root .icon-flip-horizontal,
                                                                                            :root .icon-flip-vertical {
                                                                                                                       filter: none;
                                                                                                                   }

.icon-360:before { content: "\EA01" }
.icon-arrow:before { content: "\EA02" }
.icon-check:before { content: "\EA03" }
.icon-chevron:before { content: "\EA04" }
.icon-close:before { content: "\EA05" }
.icon-elements:before { content: "\EA06" }
.icon-list:before { content: "\EA07" }
.icon-location:before { content: "\EA08" }
.icon-mail:before { content: "\EA09" }
.icon-map:before { content: "\EA0A" }
.icon-minus:before { content: "\EA0B" }
.icon-paging:before { content: "\EA0C" }
.icon-phone:before { content: "\EA0D" }
.icon-play:before { content: "\EA0E" }
.icon-plus:before { content: "\EA0F" }
.icon-search:before { content: "\EA10" }
.icon-show:before { content: "\EA11" }
.icon-soc-fb:before { content: "\EA12" }
.icon-soc-gplus:before { content: "\EA13" }
.icon-soc-insta:before { content: "\EA14" }
.icon-soc-linkedin:before { content: "\EA15" }
.icon-soc-xing:before { content: "\EA16" }
.icon-soc-yt:before { content: "\EA17" }
.icon-star:before { content: "\EA18" }
.icon-web:before { content: "\EA19" }


        :root {
--icon-360: "\EA01";
    --icon-arrow: "\EA02";
    --icon-check: "\EA03";
    --icon-chevron: "\EA04";
    --icon-close: "\EA05";
    --icon-elements: "\EA06";
    --icon-list: "\EA07";
    --icon-location: "\EA08";
    --icon-mail: "\EA09";
    --icon-map: "\EA0A";
    --icon-minus: "\EA0B";
    --icon-paging: "\EA0C";
    --icon-phone: "\EA0D";
    --icon-play: "\EA0E";
    --icon-plus: "\EA0F";
    --icon-search: "\EA10";
    --icon-show: "\EA11";
    --icon-soc-fb: "\EA12";
    --icon-soc-gplus: "\EA13";
    --icon-soc-insta: "\EA14";
    --icon-soc-linkedin: "\EA15";
    --icon-soc-xing: "\EA16";
    --icon-soc-yt: "\EA17";
    --icon-star: "\EA18";
    --icon-web: "\EA19";
    
}