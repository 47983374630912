/*affix*/
.navbar.is-transitioning {
    position: absolute;
    width: 100%;
    max-width: 1600px;
}
.navbar.is-affix {
    position: fixed !important;
    top: 0;
    width: 100%;
    max-width: 1600px;
    min-width: 982px;
}

.navbar-top .navbar-top__item a{
    font-family: var(--font-trade-gothic-regular);
}

@media screen and (min-width: 768px){
    .navbar-main{
        height: 85px;
        background: var(--color-grey);
        color: var(--color-default);
        border-bottom: 1px solid #dad7d7;
        margin-bottom: 0;
        z-index: 10;
    }

    .navbar>.container .navbar-brand{
        padding: 0;
        margin-top: 22px;
        height: auto;
        margin-left: 0;
    }

    .navbar-lang{
        position: relative;
        font-size: 14px;
    }

    .navbar-lang__button .icon{
        font-size: 22px;
        position: relative;
        top: 5px;
    }

    .navbar-lang.open .navbar-lang__button .icon{
        transform: rotate(-90deg);
    }

    .navbar-right .expand-search {
        position: relative;
        z-index: 2;
        cursor: pointer;
        padding: 5px 15px 7px;
        line-height: 20px;
        font-size: 16px;
    }

    .navbar-right .expand-search .expand-search__form {
        border-radius: 30px;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 200px;
        background-color: #fff;
        border: 0;
        box-shadow: none;
        height: 35px;
        opacity: 0;
        transform: scaleX(0);
        transform-origin: right center;
        transition: transform 250ms ease-in-out,opacity 200ms ease-in-out;
    }

    .navbar-right .expand-search.is-expanded .expand-search__form {
        -webkit-transform: scaleX(1);
        -ms-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1;
    }

    .navbar-right .navbar-search__btn {
        background: transparent;
        border: none;
        height: 40px;
        width: 40px;
        transition: all 250ms ease;
        padding: 0;
        margin-right: -6px;
        font-size: 24px;
        position: relative;
        top: 2px;
    }

    .navbar-top__links{
        margin-left: 25px;
        margin-bottom: 0;
        margin-top: 7px;
    }

    .navbar-top__links>li{
        padding: 0 13px;
    }

    .navbar-top__links>li:first-child {
        padding-left: 6px;
    }

    .navbar-top .navbar-top__item a{
        font-size: 14px;
    }

    .navbar-lang__button{
        padding: 0;
        margin-top: 3px;
    }

    .navbar-subnav__subnav{
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(100%);
        background: var(--color-grey);
        border: 1px solid #dad7d7;
        display: none;
    }

    .navbar-nav>li:hover .navbar-nav__subnav,
    .navbar-nav>li.is-open .navbar-nav__subnav{
        display: block;
    }

    .navbar-nav__subnav li:hover .navbar-subnav__subnav{
        display: block;
    }

    .navbar-left-custom {
        width: 74%;
    }
}

.navbar-main a:not(.btn-primary){
    color: var(--color-default);
    font-family: var(--font-trade-gothic-bold);
}

.navbar-main a

.navbar-nav>li>a{
    font-size: 16px;
    padding: 16px 9px;
    text-transform: uppercase;
}

.navbar-nav__subnav{
    display: none;
    background: var(--color-grey);
    border: 1px solid #dad7d7;
    position: absolute;
    min-width: 220px;
    z-index: 5;
}

.navbar-nav__subnav li{
    position: relative;
}

.navbar-nav>li ul{
    list-style: none;
    padding: 0;
}

.navbar-nav>li .navbar-nav__subnav a{
    text-transform: uppercase;
    font-size: 14px;
    display: block;
    padding: 10px 20px;
    position: relative;
}

@media screen and (min-width: 768px){
    .navbar-nav>li .navbar-nav__subnav .navbar-subnav__subnav a {
        white-space: nowrap;
    }
}

.navbar-nav>li .navbar-nav__subnav li:hover>a{
    background: #edebeb;
    color: var(--color-primary);
}

.navbar-nav>li .navbar-nav__subnav li>a .icon{
    display: none;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 22px;
}

.navbar-nav>li .navbar-nav__subnav li.has-subnav>a .icon{
    display: block;
}

@media screen and (min-width: 1400px){
    .navbar-main .navbar-nav>li>a{
        font-size: 18px;
        padding: 18px 15px 14px;
    }
}

@media screen and (min-width: 1200px){
    .container>.navbar-header{
        margin-right: 18px;
    }

    .navbar>.container .navbar-brand{
        margin-top: 13px;
    }
}


@media screen and (min-width: 768px) and (max-width: 1199px){
    .navbar-brand>img{
        width: 200px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1120px){
    .navbar-nav>li>a{
        font-size: 14px;
    }

    .navbar-right .expand-search{
        padding: 5px 5px 7px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px){
    .navbar-brand>img {
        width: 180px;
    }

    .navbar>.container .navbar-brand{
        margin-top: 24px;
    }

    .navbar-right .expand-search {
        padding: 5px 16px 7px 5px;
    }
}