@import '/static/css/libs/bootstrap.css';

/*vars*/
@import '/static/css/style/variables.css';

/*base*/
@import '/static/css/style/base.css';
@import '/static/css/style/base-layout.css';
@import '/static/css/style/typo.css';

@import '/static/css/style/icons.css';

/*components*/
/*@import '/static/css/style/lists.css';*/
/*@import '/static/css/style/form.css';*/
@import '/static/css/style/btn.css';

@import '/static/css/style/navbar.css';
/*@import '/static/css/style/navbar-mobile.css';*/
/*@import '/static/css/style/page-heading.css';*/
/*@import '/static/css/style/link-arrow.css';*/
/*@import '/static/css/style/content-teaser.css';*/
/*@import '/static/css/style/dealer-teaser.css';*/
/*@import '/static/css/style/dealer-map.css';*/
/*@import '/static/css/style/map-info.css';*/
/*@import '/static/css/style/event-teaser.css';*/
/*@import '/static/css/style/skewed-tabs.css';*/
/*@import '/static/css/style/serie-detail.css';*/
/*@import '/static/css/style/teaser.css';*/
/*@import '/static/css/style/download-teaser.css';*/
/*@import '/static/css/style/img-teaser.css';*/
/*@import '/static/css/style/news-teaser.css';*/
/*@import '/static/css/style/content-dealer-teaser.css';*/
/*@import '/static/css/style/staff-teaser.css';*/
/*@import '/static/css/style/img-text.css';*/
/*@import '/static/css/style/lightbox.css';*/
/*@import '/static/css/style/accordion.css';*/
/*@import '/static/css/style/pagination.css';*/
/*@import '/static/css/style/image-slide.css';*/
/*@import '/static/css/style/slider.css';*/
/*@import '/static/css/style/hotspot.css';*/
@import '/static/css/style/header-slider.css';
/*@import '/static/css/style/image-slider.css';*/
/*@import '/static/css/style/timeline.css';*/
/*@import '/static/css/style/custom-table.css';*/

/*@import '/static/css/style/wysiwyg.css';*/
/*@import '/static/css/style/video-js.css';*/

/*@import '/static/css/style/footer.css';*/

/* helper*/
@import '/static/css/style/oocss.css';
@import '/static/css/style/row-helper.css';
/*@import '/static/css/style/Arrange.css';*/
/*@import '/static/css/style/content-block.css';*/
@import '/static/css/style/embed-responsive.css';

.affix-scroll-top-button {
    opacity: 0;
}
