body {
    position: relative;
}
.page-wrapper {
    background: #f6f6f6;
    position: relative;
}

.container.container {
    max-width: 1430px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}

.container-outer{
    width: 100%;
    max-width: 1600px;
    margin: auto;
    background: #f6f6f6;
}

.breadcrumb > li + li:before {
    content: var(--icon-chevron);
}

@media screen and (min-width: 768px){
    .container.container--40 {
        padding: 0 40px;
        max-width: 1480px;
    }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
    body {
        width: 982px;
    }
    .container-outer{
        overflow: hidden;
    }
}

@media screen and (max-width: 767px) {
    body {
        min-width: 320px;
        max-width: 100%;
        overflow-x: hidden;
    }
    html {
        overflow-x: hidden;
    }

    .col-fluid{
        margin-left: -25px;
        margin-right: -25px;
    }

    /*.container,*/
    /*.container-fluid{*/
        /*padding-left: 25px;*/
        /*padding-right: 25px;*/
    /*}*/

    /*.container>.navbar-header{*/
        /*margin-left: -25px;*/
        /*margin-right: -25px;*/
    /*}*/
}